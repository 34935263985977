/* Footer.css */

.footer-container {
  background-color: #f8f9fa;
  padding: 5px;
}

.footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

.footer-section {
  display: flex;
  flex-direction: column;
}

.footer-section h4 {
  margin-bottom: 5px;
  font-size: 1.2em;
  font-weight: bold;
  color: #343a40;
  text-align: justify;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  text-align: justify;
}

.footer-section ul li {
  margin: 8px 0;
  text-align: justify;
}

.footer-section ul li a {
  text-decoration: none;
  color: #000000;
  font-weight: bold;
  text-align: justify;
}


.footer-section ul li a:hover {
  text-decoration: underline;
}

.social-media {
  display: flex;
  gap: 15px;
}

.social-icon {
  font-size: 24px;
  color: #495057;
  transition: color 0.3s, transform 0.3s;
}

.social-icon:hover {
  color: #007bff;
  transform: scale(1.1);
}

.social-icon.facebook {
  color: #3b5998;
}

.social-icon.instagram {
  color: #e4405f;
}

.social-icon.linkedin {
  color: #0077b5;
}

.social-icon.youtube {
  color: #ff0000;
}

.social-icon.twitter {
  color: #1da1f2;
}

.social-icon.pinterest {
  color: #e60023;
}

.footer-bottom {
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid #dee2e6;
  font-size: 0.9em;
  color: #343a40;
}

/* PopularSearch.css */
.link {
  color: grey;
  text-decoration: none;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.link:hover {
  color: black;
  /* Change color on hover */
  text-decoration: underline;
  /* Add underline on hover */
}